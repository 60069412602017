import "./app.css";
import axios from "axios";
import DetailPage from "./pages/detail";
import Home from "./pages/home";
import React, { useState, useEffect, useRef } from "react";

import { collection, getDocs } from "firebase/firestore";
import { generateToken, messaging } from "./firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import moment from "moment";
import "moment/locale/id";
import { v4 as uuidv4 } from "uuid";

function App() {
  function isIphone() {
    return /iPhone/.test(navigator.userAgent);
  }

  const [data, setData] = useState({
    current_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    event_date: moment("2024-08-31T19:30:00").format("YYYY-MM-DDTHH:mm:ss"),
  });

  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker
  //     .register("/firebase-messaging-sw.js")
  //     .then((registration) => {
  //       // console.log(
  //       //   "Service Worker registered with scope:",
  //       //   registration.scope
  //       // );
  //     })
  //     .catch((err) => {
  //       // console.error("Service Worker registration failed:", err);
  //     });
  // }

  useEffect(() => {
    const existingId = localStorage.getItem("UID");
    if (!existingId) {
      const uniqueId = uuidv4();
      localStorage.setItem("UID", uniqueId);
    }
  }, []);

  // useEffect(() => {
  //   generateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received:", payload);
  //     // alert("zzz");
  //   });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:3001/api/home")
  //     .then((response) => {
  //       setData(response.data);
  //       console.log("data: ", response.data);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const collRef = collection(db, "home");
  //       const snapshot = await getDocs(collRef);
  //       const dataList = snapshot.docs.map((doc) => ({
  //         // id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setData(dataList);
  //     } catch (err) {
  //       console.log("error", err);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const [isReversed, setIsReversed] = useState(false);
  const [detailPage, setDetailPage] = useState(false);

  const goToDetailPage = (val) => {
    // if (val) {
    //   setIsReversed(true);
    //   setDetailPage(true);
    // } else {
    //   setIsReversed(false);
    //   setDetailPage(false);
    // }
    setIsReversed(val);
    setTimeout(
      () => {
        setDetailPage(val);
      },
      val ? 1000 : 700
    );
  };

  function updateViewportHeight() {
    const viewportHeight = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${viewportHeight}px`);
  }

  // Initial setting
  updateViewportHeight();

  // Update on resize
  window.addEventListener("resize", updateViewportHeight);

  const audioRef = useRef(new Audio("/assets/piknik72.mp3"));
  const [isPlaying, setIsPlaying] = useState(false);

  // Function to play the audio
  const playMusic = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      // audio.pause();
      // setIsPlaying(false);
    } else {
      audio.play().catch((error) => {
        console.error("Playback failed:", error);
      });
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    // Cleanup function to stop audio when the component is unmounted
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Optional: Reset the audio position
      setIsPlaying(false);
    };
  }, []);

  return (
    <div className="App" onClick={playMusic}>
      <header className="App-header">
        <div
          className="body"
          style={
            {
              // height: isIphone() ? "calc(100vh - 82px)" : "100vh",
              // maxHeight: "calc(100vh - 50px)",
              // border: "1px solid red",
            }
          }
        >
          <div className="background-overlay">
            {!detailPage && data.event_date && (
              <Home
                data={data}
                isReversed={isReversed}
                setDetailPage={goToDetailPage}
              />
            )}
            {detailPage && data.event_date && (
              <DetailPage data={data} setDetailPage={goToDetailPage} />
            )}
          </div>
          <div className="copyright">© 2024 Selebrasi.</div>
        </div>
      </header>
    </div>
  );
}

export default App;
