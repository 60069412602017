import React from "react";
import { useSpring, animated } from "@react-spring/web";
import styles from "./index.module.css";

const PoliceLine = ({ isReversed = false, direction = "left", delay }) => {
  const linearStyle1 = {
    background: `repeating-linear-gradient(${
      direction === "left" ? "90deg" : "-90deg"
    }, white, white 20px, black 20px, black 40px)`,
  };

  const linearStyle2 = {
    background: `repeating-linear-gradient(${
      direction === "left" ? "90deg" : "-90deg"
    }, black, black 20px, white 20px, white 40px)`,
  };

  const style = useSpring({
    // from: { transform: "translateX(-100%)" },
    // to: { transform: "translateX(100%)" },
    from: { width: isReversed ? "100%" : "0%" },
    to: { width: isReversed ? "0%" : "100%" },
    // reset: true,
    // reverse: true,
    // loop: true,
    config: { duration: isReversed ? 300 : 300 },
    // config: { duration: isReversed ? 300 : 800, easing: (t) => t * (2 - t) },
    delay: delay ? (isReversed ? 300 : delay) : delay,
  });

  return (
    <div className="police-line-container ">
      <animated.div
        className={styles["police-line"]}
        style={{
          ...style,
          ...(direction === "left"
            ? { top: 0, left: 0 }
            : { bottom: 20, right: 0 }),
          ...linearStyle1,
        }}
      ></animated.div>
      <animated.div
        className={styles["police-line"]}
        style={{
          ...style,
          ...(direction === "left"
            ? { top: 20, left: 0 }
            : { bottom: 0, right: 0 }),
          ...linearStyle2,
        }}
      ></animated.div>
    </div>
  );
};

export default PoliceLine;
