import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
export default function Maps() {
  return (
    <MapContainer
      center={[-6.23626, 106.747272]}
      zoom={20}
      style={{ width: "100%", maxWidth: "260px", height: "200px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        //   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[-6.23626, 106.747272]}>
        <Popup>Depan tukang cireng.</Popup>
      </Marker>
    </MapContainer>
  );
}
