import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";

export default function FadeText({ isReversed, from, delay, children }) {
  const transformValue =
    from === "left"
      ? "translateX(-50px)"
      : from === "right"
      ? "translateX(50px)"
      : "translateX(0px)"; // Default value

  const props = useSpring({
    opacity: isReversed ? 0 : 1,
    transform: isReversed ? transformValue : "translateX(0px)",
    from: {
      opacity: isReversed ? 1 : 0,
      transform: transformValue,
    },
    config: { duration: 200 }, // Duration in milliseconds
    delay,
  });

  return <animated.div style={{ ...props }}>{children}</animated.div>;
}
