import React, { useState } from "react";
import PoliceLine from "../../components/police-line";
import styles from "./index.module.css";
import { useSpring, animated } from "@react-spring/web";
import FadeText from "../../components/fade-text";
import Countdown from "../../components/countdown";
import moment from "moment";
import DetailPage from "../detail";

export default function Home({ data, isReversed, setDetailPage }) {
  const currentDate = moment(data.current_date, "YYYY-MM-DDTHH:mm:ss");
  const startDate = moment(data.event_date, "YYYY-MM-DDTHH:mm:ss");

  const diffMilliseconds = startDate.diff(currentDate);

  // Create a duration object from the difference
  const diffDuration = moment.duration(diffMilliseconds);

  // const diffDuration = moment.duration(endDate.diff(startDate));
  const diffInSeconds = diffDuration.asSeconds();
  const diffInDays = diffDuration.asDays();

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        // height: "100%",
        height: "100%",
        paddingTop: 0,
      }}
    >
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <PoliceLine isReversed={isReversed} />
      </div>

      <div className={styles["content-container"]}>
        <div
          className={[
            styles["title-container"],
            "lobster",
            "heading-1",
            "text-white",
          ].join(" ")}
        >
          <FadeText isReversed={isReversed} from="left" delay={300}>
            <div className={styles["title-1"]}>Night</div>
          </FadeText>
          <FadeText isReversed={isReversed} from="right" delay={300}>
            <div className={styles["title-2"]}>Ride!</div>
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Permanent Marker",
                marginLeft: "80px",
                marginTop: "-18px",
              }}
            >
              <span style={{ fontSize: 12 }}>with </span>
              Selebrasi
            </div>
          </FadeText>
        </div>

        <FadeText isReversed={isReversed} delay={400}>
          <div
            className={styles["coming-soon-title"]}
            style={{ fontSize: "20px" }}
          >
            Event Telah Berakhir
          </div>
        </FadeText>

        {/* <div className={styles["coming-soon-container"]}>
          <FadeText isReversed={isReversed} delay={400}>
            <div className={styles["coming-soon-title"]}>Akan mulai dalam</div>
          </FadeText>

          <div className={styles["coming-soon-timer-wrapper"]}>
            <Countdown
              isReversed={isReversed}
              bgColor="#0E86D4"
              totalSeconds={diffInSeconds}
            />
          </div>
        </div>

        <FadeText isReversed={isReversed} delay={500}>
          <div className={styles["caption"]}>
            Naik <span className="cross-out">vespa</span> mio keliling kota
            sampai binariaaa~
          </div>
        </FadeText>

        <FadeText isReversed={isReversed} delay={600}>
          <div className={styles["caption-2"]}>
            Mengundang seluruh anggota <b>Selebrasi</b> untuk hadir dalam event
            Night Ride, mari kita gaskan kawan...
          </div>
        </FadeText>

        <FadeText isReversed={isReversed} delay={500}>
          <div
            className={styles["button-detail"]}
            onClick={() => setDetailPage(true)}
          >
            Lihat Detail
          </div>
        </FadeText>

        <div className={styles["sponsor-container"]}>
          <FadeText isReversed={isReversed} delay={800}>
            <div className={styles["sponsor"]}>
              <div>Mie ayam pakde</div>
              <div>Kebun Belakang</div>
              <div>Warmindo 33</div>
            </div>
          </FadeText>
        </div> */}
      </div>

      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <PoliceLine isReversed={isReversed} direction="right" delay={200} />
      </div>
    </div>
  );
}
