import React, { useState, useRef, useEffect } from "react";
import styles from "./index.module.css";
import GoogleMapReact from "google-map-react";
import Maps from "../../components/maps";
import FadeText from "../../components/fade-text";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import "moment/locale/id";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
moment.locale("id");

export default function DetailPage({ data, setDetailPage }) {
  const newDate = data.event_date.split(".")[0];

  const formattedDate = moment(newDate).format("DD MMMM YYYY HH:mm:ss");
  const day = moment(newDate).format("dddd");
  const date = moment(newDate).format("DD");
  const month = moment(newDate).format("MMMM");
  const year = moment(newDate).format("YYYY");
  const time = moment(newDate).format("HH:mm");
  const uid = localStorage.getItem("UID");

  const [isReversed, setIsReversed] = useState(false);
  const [form, setForm] = useState({
    user: "",
    message: "",
    uid: uid,
  });

  const [chats, setChats] = useState([
    {
      user: "Tukang Cireng",
      message: "Napa didepan gerobak gua.",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Anonymous",
      message:
        "Dimananya si bang gua gatau soalnya, deket halte adam malik bukan?",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Anonymous001",
      message: "Ni dimana bang lokasinya. ntar gua",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Tukang Gorengan",
      message: "Ni dimana bang lokasinya.",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "You",
      message: "Deket Kampus budi luhur.",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Wahyu",
      message: "Gua ga bisa masterrrr",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Alwan",
      message: "OTW Dim",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Khairul",
      message: "Dimana ini hu ane nyusul pake win",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Ilhamsyah",
      message: "Jam berapa ini dim kemana aja motorannya",
      created_date: "11-08-2024 10:59:10",
    },
    {
      user: "Helmi",
      message: "gua ikut mas",
      created_date: "11-08-2024 10:59:10",
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState();

  const targetRef = useRef(null);
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const isInitialRender = useRef(true);
  const [currentSection, setCurrentSection] = useState("");

  // useEffect(() => {
  //   const detailContainer =
  //     document.getElementsByClassName("detail-container")[0];
  //   if (detailContainer) {
  //     detailContainer.style.overflowY = "hidden";
  //   }
  // }, []);

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
    // setTimeout(() => {
    //   setCurrentSection(sectionId);
    // }, 500);
  };

  // useEffect(() => {
  //   if (currentSection != "") {
  //     console.log("currentSection", currentSection);
  //     const section = document.getElementById(section);
  //     console.log("section", section);
  //     // if (section) {
  //     section.scrollIntoView({ behavior: "smooth" });
  //   }
  //   // }
  // }, [currentSection]);

  useEffect(() => {
    const detailContainer = document.getElementById("detail-container");
    // Disable scrolling when the component is mounted
    detailContainer.style.overflow = "hidden";

    // Re-enable scrolling when the component is unmounted
    return () => {
      detailContainer.style.overflow = "auto";
    };
  }, []);

  const handleForm = (e, key) => {
    setForm((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const submit = async () => {
    const form_ = form;
    setForm((prev) => ({ ...prev, message: "" }));

    // setTimeout(async () => {
    //   if (form.user && form.message) {
    //     setChats((prev) => [...prev, form_]);
    //   }
    // }, 300);

    if (form.user && form.message && form.message?.trim() !== "") {
      if (form.user?.length >= 20) {
        alert("namanya kepanjangan");
      } else {
        if (form.message?.length >= 50) {
          alert("nulis pesan apa surat wasiat panjang amat");
        } else {
          await addDoc(collection(db, "messages"), {
            text: form.message,
            user: form.user,
            createdAt: serverTimestamp(),
            uid: form.uid,
          });
        }
      }
    }
  };

  useEffect(() => {
    // if (currentSection === "section2") {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    // }
  }, [currentSection, messages]);

  const back = () => {
    setIsReversed(true);
    setDetailPage(false);
  };

  function updateViewportHeight() {
    const viewportHeight = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${viewportHeight}px`);
  }

  // Initial setting
  updateViewportHeight();

  // Update on resize
  window.addEventListener("resize", updateViewportHeight);

  useEffect(() => {
    setLoading(true);

    const q = query(
      collection(db, "messages"),
      orderBy("createdAt", "desc")
      // limit(50)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        const data = doc.data();
        const newDate = new Date(
          data?.createdAt?.seconds * 1000 +
            data?.createdAt?.nanoseconds / 1000000
        ); // Convert seconds
        const createdAt = newDate.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        });

        fetchedMessages.push({
          ...doc.data(),
          createdAt_: createdAt,
          id: doc.id,
        });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      setLoading(false);
      setMessages(sortedMessages);
    });
    return () => unsubscribe;
  }, []);

  return (
    <div
      id="detail-container"
      style={{
        // position: "relative",
        // width: "100%",
        // height: "100%",
        height: "100%",
        // paddingTop: 0,
        // border: "1px solid blue",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            height: "100%",
            margin: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div className={styles["top-bar"]} onClick={() => back()}>
            <FadeText isReversed={isReversed} delay={100}>
              <KeyboardBackspaceIcon
                style={{ fontSize: "30px", color: "white" }}
              />
            </FadeText>
          </div>
          <div id="section1" style={{ height: "100vh" }}>
            {/* Content */}
            <div className={styles["maps-chant-container"]}>
              <FadeText isReversed={isReversed} delay={100}>
                <div className={styles["location"]}>Lokasi</div>
              </FadeText>
              <FadeText isReversed={isReversed} delay={200}>
                <Maps />
              </FadeText>
              <FadeText isReversed={isReversed} delay={250}>
                <div className={styles["detail"]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${day}, <b>${date} ${month} ${year}</b>`,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `Pukul <b>${time}</b>`,
                    }}
                  />
                  <div>
                    Jakarta Selatan, RT.10/RW.2, Petukangan Utara, Kec.
                    Pesanggrahan, Kota Jakarta Selatan, Daerah Khusus Ibukota
                    Jakarta 12260
                  </div>
                </div>
              </FadeText>

              <KeyboardDoubleArrowDownIcon
                onClick={() => handleScroll("section2")}
                style={{
                  margin: "0 auto",
                  color: "#ffff",
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div id="section2" style={{ height: "100vh" }}>
            <KeyboardDoubleArrowUpIcon
              onClick={() => handleScroll("section1")}
              style={{
                margin: "0 auto",
                marginTop: "40px",
                color: "#ffff",
                fontSize: "40px",
                cursor: "pointer",
              }}
            />

            <div style={{ width: "340px", margin: "0 auto" }}>
              <div
                id="chat-container"
                className={styles["chat-container"]}
                ref={chatContainerRef}
              >
                {messages?.map((item, index) => {
                  return (
                    <div key={index} className={styles["chat"]}>
                      <div
                        className={
                          styles[item.uid === uid ? "chat-user-2" : "chat-user"]
                        }
                        style={{ fontWeight: "600" }}
                      >
                        {item.user}
                      </div>
                      <div
                        className={
                          styles[
                            item.uid === uid ? "chat-message-2" : "chat-message"
                          ]
                        }
                      >
                        <div>{item.text}</div>
                        <div className={styles["chat-date"]}>
                          {item.createdAt_}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div ref={chatEndRef} />
              </div>

              <div className={styles["input-chat"]}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  style={{
                    display: "flex",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    label="Nama"
                    value={form?.user}
                    variant="standard"
                    onChange={(e) => handleForm(e, "user")}
                    sx={{
                      width: "120px",
                      "& .MuiInputLabel-root": {
                        color: "grey", // Change label color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "grey", // Change color when focused
                      },
                    }}
                  />
                </Box>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    label="Pesan"
                    value={form?.message}
                    variant="standard"
                    onChange={(e) => handleForm(e, "message")}
                    sx={{
                      width: "100%",
                      "& .MuiInputLabel-root": {
                        color: "grey", // Change label color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "grey", // Change color when focused
                      },
                    }}
                  />

                  <SendIcon style={{ color: "white" }} onClick={submit} />
                </Box>

                {/* <input
              className={styles["input-name"]}
              value={form?.user}
              placeholder="Name"
              onChange={(e) => handleForm(e, "user")}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <input
                className={styles["input-message"]}
                value={form?.message}
                placeholder="Message"
                onChange={(e) => handleForm(e, "message")}
              /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
