// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// const messaging = getMessaging(app);

// const generateToken = async () => {
//   const permission = await Notification.requestPermission();
//   // console.log("permission", permission);

//   if (permission === "granted") {
//     const token = await getToken(messaging, {
//       vapidKey:
//         "BBsqw15-Jevot8cc8WtMmr1iWAGWmsTzwBP7x0NntiEEtbGejP86Nysap0GoaE9idtgPz6tzS1up3eeSrC1Bm_s",
//     });

//     // console.log("token", token);
//   }
// };

export { db };

// Request permission to receive notifications
// getToken(messaging, { vapidKey: vapidKey })
//   .then((currentToken) => {
//     if (currentToken) {
//       console.log("FCM Token:", currentToken);
//       // Send the token to your server and store it
//     } else {
//       console.log(
//         "No registration token available. Request permission to generate one."
//       );
//     }
//   })
//   .catch((err) => {
//     console.error("An error occurred while retrieving token.", err);
//   });

// // Handle incoming messages
// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   // Customize notification behavior here
// });
